/* eslint-disable */
// @generated by protobuf-ts 2.9.6 with parameter long_type_string,optimize_code_size,eslint_disable,ts_nocheck
// @generated from protobuf file "soulfire/script.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message soulfire.v1.GlobalScriptScope
 */
export interface GlobalScriptScope {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceScriptScope
 */
export interface InstanceScriptScope {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.ScriptScope
 */
export interface ScriptScope {
    /**
     * @generated from protobuf oneof: scope
     */
    scope: {
        oneofKind: "globalScript";
        /**
         * @generated from protobuf field: soulfire.v1.GlobalScriptScope global_script = 1;
         */
        globalScript: GlobalScriptScope;
    } | {
        oneofKind: "instanceScript";
        /**
         * @generated from protobuf field: soulfire.v1.InstanceScriptScope instance_script = 2;
         */
        instanceScript: InstanceScriptScope;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.CreateScriptRequest
 */
export interface CreateScriptRequest {
    /**
     * @generated from protobuf field: soulfire.v1.ScriptScope scope = 1;
     */
    scope?: ScriptScope;
    /**
     * @generated from protobuf field: string script_name = 2;
     */
    scriptName: string;
    /**
     * @generated from protobuf field: bool elevated_permissions = 3;
     */
    elevatedPermissions: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.CreateScriptResponse
 */
export interface CreateScriptResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.DeleteScriptRequest
 */
export interface DeleteScriptRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.DeleteScriptResponse
 */
export interface DeleteScriptResponse {
}
/**
 * @generated from protobuf message soulfire.v1.RestartScriptRequest
 */
export interface RestartScriptRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message soulfire.v1.RestartScriptResponse
 */
export interface RestartScriptResponse {
}
/**
 * @generated from protobuf message soulfire.v1.UpdateScriptRequest
 */
export interface UpdateScriptRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string script_name = 2;
     */
    scriptName: string;
    /**
     * @generated from protobuf field: bool elevated_permissions = 3;
     */
    elevatedPermissions: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.UpdateScriptResponse
 */
export interface UpdateScriptResponse {
}
/**
 * @generated from protobuf message soulfire.v1.ScriptListRequest
 */
export interface ScriptListRequest {
    /**
     * @generated from protobuf field: soulfire.v1.ScriptScope scope = 1;
     */
    scope?: ScriptScope;
}
/**
 * @generated from protobuf message soulfire.v1.ScriptListResponse
 */
export interface ScriptListResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.ScriptListResponse.Script scripts = 1;
     */
    scripts: ScriptListResponse_Script[];
}
/**
 * @generated from protobuf message soulfire.v1.ScriptListResponse.Script
 */
export interface ScriptListResponse_Script {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string script_name = 2;
     */
    scriptName: string;
    /**
     * @generated from protobuf field: bool elevated_permissions = 3;
     */
    elevatedPermissions: boolean;
    /**
     * @generated from protobuf field: soulfire.v1.ScriptLanguage language = 4;
     */
    language: ScriptLanguage;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: soulfire.v1.ScriptScope script_scope = 7;
     */
    scriptScope?: ScriptScope;
}
/**
 * @generated from protobuf enum soulfire.v1.ScriptLanguage
 */
export enum ScriptLanguage {
    /**
     * @generated from protobuf enum value: JAVASCRIPT = 0;
     */
    JAVASCRIPT = 0,
    /**
     * @generated from protobuf enum value: PYTHON = 1;
     */
    PYTHON = 1,
    /**
     * @generated from protobuf enum value: TYPESCRIPT = 2;
     */
    TYPESCRIPT = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GlobalScriptScope$Type extends MessageType<GlobalScriptScope> {
    constructor() {
        super("soulfire.v1.GlobalScriptScope", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalScriptScope
 */
export const GlobalScriptScope = new GlobalScriptScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceScriptScope$Type extends MessageType<InstanceScriptScope> {
    constructor() {
        super("soulfire.v1.InstanceScriptScope", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceScriptScope
 */
export const InstanceScriptScope = new InstanceScriptScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScriptScope$Type extends MessageType<ScriptScope> {
    constructor() {
        super("soulfire.v1.ScriptScope", [
            { no: 1, name: "global_script", kind: "message", oneof: "scope", T: () => GlobalScriptScope },
            { no: 2, name: "instance_script", kind: "message", oneof: "scope", T: () => InstanceScriptScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ScriptScope
 */
export const ScriptScope = new ScriptScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateScriptRequest$Type extends MessageType<CreateScriptRequest> {
    constructor() {
        super("soulfire.v1.CreateScriptRequest", [
            { no: 1, name: "scope", kind: "message", T: () => ScriptScope },
            { no: 2, name: "script_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "elevated_permissions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CreateScriptRequest
 */
export const CreateScriptRequest = new CreateScriptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateScriptResponse$Type extends MessageType<CreateScriptResponse> {
    constructor() {
        super("soulfire.v1.CreateScriptResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.CreateScriptResponse
 */
export const CreateScriptResponse = new CreateScriptResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteScriptRequest$Type extends MessageType<DeleteScriptRequest> {
    constructor() {
        super("soulfire.v1.DeleteScriptRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.DeleteScriptRequest
 */
export const DeleteScriptRequest = new DeleteScriptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteScriptResponse$Type extends MessageType<DeleteScriptResponse> {
    constructor() {
        super("soulfire.v1.DeleteScriptResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.DeleteScriptResponse
 */
export const DeleteScriptResponse = new DeleteScriptResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestartScriptRequest$Type extends MessageType<RestartScriptRequest> {
    constructor() {
        super("soulfire.v1.RestartScriptRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.RestartScriptRequest
 */
export const RestartScriptRequest = new RestartScriptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestartScriptResponse$Type extends MessageType<RestartScriptResponse> {
    constructor() {
        super("soulfire.v1.RestartScriptResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.RestartScriptResponse
 */
export const RestartScriptResponse = new RestartScriptResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateScriptRequest$Type extends MessageType<UpdateScriptRequest> {
    constructor() {
        super("soulfire.v1.UpdateScriptRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "script_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "elevated_permissions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateScriptRequest
 */
export const UpdateScriptRequest = new UpdateScriptRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateScriptResponse$Type extends MessageType<UpdateScriptResponse> {
    constructor() {
        super("soulfire.v1.UpdateScriptResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateScriptResponse
 */
export const UpdateScriptResponse = new UpdateScriptResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScriptListRequest$Type extends MessageType<ScriptListRequest> {
    constructor() {
        super("soulfire.v1.ScriptListRequest", [
            { no: 1, name: "scope", kind: "message", T: () => ScriptScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ScriptListRequest
 */
export const ScriptListRequest = new ScriptListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScriptListResponse$Type extends MessageType<ScriptListResponse> {
    constructor() {
        super("soulfire.v1.ScriptListResponse", [
            { no: 1, name: "scripts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ScriptListResponse_Script }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ScriptListResponse
 */
export const ScriptListResponse = new ScriptListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScriptListResponse_Script$Type extends MessageType<ScriptListResponse_Script> {
    constructor() {
        super("soulfire.v1.ScriptListResponse.Script", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "script_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "elevated_permissions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "language", kind: "enum", T: () => ["soulfire.v1.ScriptLanguage", ScriptLanguage] },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "script_scope", kind: "message", T: () => ScriptScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ScriptListResponse.Script
 */
export const ScriptListResponse_Script = new ScriptListResponse_Script$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.ScriptService
 */
export const ScriptService = new ServiceType("soulfire.v1.ScriptService", [
    { name: "CreateScript", options: {}, I: CreateScriptRequest, O: CreateScriptResponse },
    { name: "DeleteScript", options: {}, I: DeleteScriptRequest, O: DeleteScriptResponse },
    { name: "RestartScript", options: {}, I: RestartScriptRequest, O: RestartScriptResponse },
    { name: "UpdateScript", options: {}, I: UpdateScriptRequest, O: UpdateScriptResponse },
    { name: "ListScripts", options: {}, I: ScriptListRequest, O: ScriptListResponse }
]);
