/* eslint-disable */
// @generated by protobuf-ts 2.9.6 with parameter long_type_string,optimize_code_size,eslint_disable,ts_nocheck
// @generated from protobuf file "soulfire/common.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MessageType } from "@protobuf-ts/runtime";
import { Value } from "../google/protobuf/struct";
import { Struct } from "../google/protobuf/struct";
/**
 * @generated from protobuf message soulfire.v1.ProxyProto
 */
export interface ProxyProto {
    /**
     * @generated from protobuf field: soulfire.v1.ProxyProto.Type type = 1;
     */
    type: ProxyProto_Type;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: optional string username = 3;
     */
    username?: string;
    /**
     * @generated from protobuf field: optional string password = 4;
     */
    password?: string;
}
/**
 * @generated from protobuf enum soulfire.v1.ProxyProto.Type
 */
export enum ProxyProto_Type {
    /**
     * @generated from protobuf enum value: HTTP = 0;
     */
    HTTP = 0,
    /**
     * @generated from protobuf enum value: SOCKS4 = 1;
     */
    SOCKS4 = 1,
    /**
     * @generated from protobuf enum value: SOCKS5 = 2;
     */
    SOCKS5 = 2
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto
 */
export interface MinecraftAccountProto {
    /**
     * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.AccountTypeProto type = 1;
     */
    type: MinecraftAccountProto_AccountTypeProto;
    /**
     * @generated from protobuf field: string profile_id = 2;
     */
    profileId: string;
    /**
     * @generated from protobuf field: string last_known_name = 3;
     */
    lastKnownName: string;
    /**
     * @generated from protobuf oneof: account_data
     */
    accountData: {
        oneofKind: "onlineSimpleJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData online_simple_java_data = 4;
         */
        onlineSimpleJavaData: MinecraftAccountProto_OnlineSimpleJavaData;
    } | {
        oneofKind: "onlineChainJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OnlineChainJavaData online_chain_java_data = 5;
         */
        onlineChainJavaData: MinecraftAccountProto_OnlineChainJavaData;
    } | {
        oneofKind: "offlineJavaData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.OfflineJavaData offline_java_data = 6;
         */
        offlineJavaData: MinecraftAccountProto_OfflineJavaData;
    } | {
        oneofKind: "bedrockData";
        /**
         * @generated from protobuf field: soulfire.v1.MinecraftAccountProto.BedrockData bedrock_data = 7;
         */
        bedrockData: MinecraftAccountProto_BedrockData;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData
 */
export interface MinecraftAccountProto_OnlineSimpleJavaData {
    /**
     * @generated from protobuf field: string auth_token = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: int64 token_expire_at = 2;
     */
    tokenExpireAt: string;
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OnlineChainJavaData
 */
export interface MinecraftAccountProto_OnlineChainJavaData {
    /**
     * @generated from protobuf field: string auth_token = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: int64 token_expire_at = 2;
     */
    tokenExpireAt: string;
    /**
     * @generated from protobuf field: google.protobuf.Struct auth_chain = 3;
     */
    authChain?: Struct;
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.OfflineJavaData
 */
export interface MinecraftAccountProto_OfflineJavaData {
}
/**
 * @generated from protobuf message soulfire.v1.MinecraftAccountProto.BedrockData
 */
export interface MinecraftAccountProto_BedrockData {
    /**
     * @generated from protobuf field: string mojang_jwt = 1;
     */
    mojangJwt: string;
    /**
     * @generated from protobuf field: string identity_jwt = 2;
     */
    identityJwt: string;
    /**
     * @generated from protobuf field: string public_key = 3;
     */
    publicKey: string;
    /**
     * @generated from protobuf field: string private_key = 4;
     */
    privateKey: string;
    /**
     * @generated from protobuf field: string device_id = 5;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string play_fab_id = 6;
     */
    playFabId: string;
    /**
     * @generated from protobuf field: google.protobuf.Struct auth_chain = 7;
     */
    authChain?: Struct;
}
/**
 * @generated from protobuf enum soulfire.v1.MinecraftAccountProto.AccountTypeProto
 */
export enum MinecraftAccountProto_AccountTypeProto {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_CREDENTIALS = 0;
     */
    MICROSOFT_JAVA_CREDENTIALS = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_CREDENTIALS = 1;
     */
    MICROSOFT_BEDROCK_CREDENTIALS = 1,
    /**
     * @generated from protobuf enum value: THE_ALTENING = 3;
     */
    THE_ALTENING = 3,
    /**
     * @generated from protobuf enum value: OFFLINE = 4;
     */
    OFFLINE = 4,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_DEVICE_CODE = 5;
     */
    MICROSOFT_JAVA_DEVICE_CODE = 5,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_DEVICE_CODE = 6;
     */
    MICROSOFT_BEDROCK_DEVICE_CODE = 6,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_REFRESH_TOKEN = 7;
     */
    MICROSOFT_JAVA_REFRESH_TOKEN = 7
}
/**
 * @generated from protobuf message soulfire.v1.SettingsEntry
 */
export interface SettingsEntry {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: google.protobuf.Value value = 2;
     */
    value?: Value;
}
/**
 * @generated from protobuf message soulfire.v1.SettingsNamespace
 */
export interface SettingsNamespace {
    /**
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsEntry entries = 2;
     */
    entries: SettingsEntry[];
}
/**
 * @generated from protobuf message soulfire.v1.StringSetting
 */
export interface StringSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string def = 3;
     */
    def: string;
    /**
     * @generated from protobuf field: soulfire.v1.StringSetting.InputType input_type = 10;
     */
    inputType: StringSetting_InputType;
    /**
     * @generated from protobuf field: string placeholder = 6;
     */
    placeholder: string;
    /**
     * @generated from protobuf field: int32 min_length = 7;
     */
    minLength: number;
    /**
     * @generated from protobuf field: int32 max_length = 8;
     */
    maxLength: number;
    /**
     * @generated from protobuf field: string pattern = 9;
     */
    pattern: string;
    /**
     * @generated from protobuf field: bool disabled = 11;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf enum soulfire.v1.StringSetting.InputType
 */
export enum StringSetting_InputType {
    /**
     * @generated from protobuf enum value: TEXT = 0;
     */
    TEXT = 0,
    /**
     * @generated from protobuf enum value: PASSWORD = 1;
     */
    PASSWORD = 1,
    /**
     * @generated from protobuf enum value: EMAIL = 2;
     */
    EMAIL = 2,
    /**
     * @generated from protobuf enum value: SEARCH = 3;
     */
    SEARCH = 3,
    /**
     * @generated from protobuf enum value: TEL = 4;
     */
    TEL = 4,
    /**
     * @generated from protobuf enum value: URL = 5;
     */
    URL = 5,
    /**
     * @generated from protobuf enum value: TEXTAREA = 6;
     */
    TEXTAREA = 6
}
/**
 * @generated from protobuf message soulfire.v1.IntSetting
 */
export interface IntSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: int32 def = 3;
     */
    def: number;
    /**
     * @generated from protobuf field: int32 min = 4;
     */
    min: number;
    /**
     * @generated from protobuf field: int32 max = 5;
     */
    max: number;
    /**
     * @generated from protobuf field: int32 step = 6;
     */
    step: number;
    /**
     * @generated from protobuf field: string placeholder = 7;
     */
    placeholder: string;
    /**
     * @generated from protobuf field: bool thousand_separator = 8;
     */
    thousandSeparator: boolean;
    /**
     * @generated from protobuf field: bool disabled = 9;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.DoubleSetting
 */
export interface DoubleSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: double def = 3;
     */
    def: number;
    /**
     * @generated from protobuf field: double min = 4;
     */
    min: number;
    /**
     * @generated from protobuf field: double max = 5;
     */
    max: number;
    /**
     * @generated from protobuf field: double step = 6;
     */
    step: number;
    /**
     * @generated from protobuf field: string placeholder = 7;
     */
    placeholder: string;
    /**
     * @generated from protobuf field: bool thousand_separator = 8;
     */
    thousandSeparator: boolean;
    /**
     * @generated from protobuf field: int32 decimal_scale = 9;
     */
    decimalScale: number;
    /**
     * @generated from protobuf field: bool fixed_decimal_scale = 10;
     */
    fixedDecimalScale: boolean;
    /**
     * @generated from protobuf field: bool disabled = 11;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.BoolSetting
 */
export interface BoolSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: bool def = 3;
     */
    def: boolean;
    /**
     * @generated from protobuf field: bool disabled = 4;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.ComboSetting
 */
export interface ComboSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * List of options
     *
     * @generated from protobuf field: repeated soulfire.v1.ComboSetting.Option options = 3;
     */
    options: ComboSetting_Option[];
    /**
     * @generated from protobuf field: string def = 4;
     */
    def: string;
    /**
     * @generated from protobuf field: bool disabled = 5;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.ComboSetting.Option
 */
export interface ComboSetting_Option {
    /**
     * Sent to server
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Displayed to user
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * May be used for an icon for an option
     *
     * @generated from protobuf field: optional string icon_id = 3;
     */
    iconId?: string;
    /**
     * keywords to also use for filtering
     *
     * @generated from protobuf field: repeated string keywords = 4;
     */
    keywords: string[];
}
/**
 * @generated from protobuf message soulfire.v1.StringListSetting
 */
export interface StringListSetting {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string def = 3;
     */
    def: string[];
    /**
     * @generated from protobuf field: bool disabled = 4;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.MinMaxSetting
 */
export interface MinMaxSetting {
    /**
     * @generated from protobuf field: int32 min = 1;
     */
    min: number;
    /**
     * @generated from protobuf field: int32 max = 2;
     */
    max: number;
    /**
     * @generated from protobuf field: int32 step = 3;
     */
    step: number;
    /**
     * @generated from protobuf field: bool thousand_separator = 4;
     */
    thousandSeparator: boolean;
    /**
     * @generated from protobuf field: soulfire.v1.MinMaxSetting.Entry minEntry = 5;
     */
    minEntry?: MinMaxSetting_Entry;
    /**
     * @generated from protobuf field: soulfire.v1.MinMaxSetting.Entry maxEntry = 6;
     */
    maxEntry?: MinMaxSetting_Entry;
    /**
     * @generated from protobuf field: bool disabled = 7;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.MinMaxSetting.Entry
 */
export interface MinMaxSetting_Entry {
    /**
     * @generated from protobuf field: string ui_name = 1;
     */
    uiName: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: int32 def = 3;
     */
    def: number;
    /**
     * @generated from protobuf field: string placeholder = 4;
     */
    placeholder: string;
}
/**
 * A entry in the settings page
 *
 * @generated from protobuf message soulfire.v1.SettingEntry
 */
export interface SettingEntry {
    /**
     * Basically we only send a kv map to the server with every setting entry
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "string";
        /**
         * @generated from protobuf field: soulfire.v1.StringSetting string = 2;
         */
        string: StringSetting;
    } | {
        oneofKind: "int";
        /**
         * @generated from protobuf field: soulfire.v1.IntSetting int = 3;
         */
        int: IntSetting;
    } | {
        oneofKind: "double";
        /**
         * @generated from protobuf field: soulfire.v1.DoubleSetting double = 4;
         */
        double: DoubleSetting;
    } | {
        oneofKind: "bool";
        /**
         * @generated from protobuf field: soulfire.v1.BoolSetting bool = 5;
         */
        bool: BoolSetting;
    } | {
        oneofKind: "combo";
        /**
         * @generated from protobuf field: soulfire.v1.ComboSetting combo = 6;
         */
        combo: ComboSetting;
    } | {
        oneofKind: "stringList";
        /**
         * @generated from protobuf field: soulfire.v1.StringListSetting string_list = 7;
         */
        stringList: StringListSetting;
    } | {
        oneofKind: "minMax";
        /**
         * @generated from protobuf field: soulfire.v1.MinMaxSetting min_max = 8;
         */
        minMax: MinMaxSetting;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.SettingsPage
 */
export interface SettingsPage {
    /**
     * @generated from protobuf field: optional soulfire.v1.ServerPlugin owning_plugin = 2;
     */
    owningPlugin?: ServerPlugin;
    /**
     * The name of the page for these settings
     *
     * @generated from protobuf field: string page_name = 3;
     */
    pageName: string;
    /**
     * What namespace the settings of this page represent
     *
     * @generated from protobuf field: string namespace = 4;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingEntry entries = 5;
     */
    entries: SettingEntry[];
    /**
     * https://lucide.dev icon id for this page (Usually rendered left of the page name)
     *
     * @generated from protobuf field: string icon_id = 6;
     */
    iconId: string;
    /**
     * Key which makes this plugin "enabled" or "disabled"
     *
     * @generated from protobuf field: optional string enabled_key = 7;
     */
    enabledKey?: string;
}
/**
 * @generated from protobuf message soulfire.v1.ServerPlugin
 */
export interface ServerPlugin {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string author = 4;
     */
    author: string;
    /**
     * @generated from protobuf field: string license = 5;
     */
    license: string;
    /**
     * @generated from protobuf field: string website = 6;
     */
    website: string;
}
/**
 * @generated from protobuf enum soulfire.v1.AccountTypeCredentials
 */
export enum AccountTypeCredentials {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_CREDENTIALS = 0;
     */
    MICROSOFT_JAVA_CREDENTIALS = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_CREDENTIALS = 1;
     */
    MICROSOFT_BEDROCK_CREDENTIALS = 1,
    /**
     * @generated from protobuf enum value: THE_ALTENING = 3;
     */
    THE_ALTENING = 3,
    /**
     * @generated from protobuf enum value: OFFLINE = 4;
     */
    OFFLINE = 4,
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_REFRESH_TOKEN = 5;
     */
    MICROSOFT_JAVA_REFRESH_TOKEN = 5
}
/**
 * @generated from protobuf enum soulfire.v1.AccountTypeDeviceCode
 */
export enum AccountTypeDeviceCode {
    /**
     * @generated from protobuf enum value: MICROSOFT_JAVA_DEVICE_CODE = 0;
     */
    MICROSOFT_JAVA_DEVICE_CODE = 0,
    /**
     * @generated from protobuf enum value: MICROSOFT_BEDROCK_DEVICE_CODE = 1;
     */
    MICROSOFT_BEDROCK_DEVICE_CODE = 1
}
/**
 * @generated from protobuf enum soulfire.v1.GlobalPermission
 */
export enum GlobalPermission {
    /**
     * @generated from protobuf enum value: CREATE_INSTANCE = 0;
     */
    CREATE_INSTANCE = 0,
    /**
     * @generated from protobuf enum value: GLOBAL_SUBSCRIBE_LOGS = 1;
     */
    GLOBAL_SUBSCRIBE_LOGS = 1,
    /**
     * @generated from protobuf enum value: READ_CLIENT_DATA = 2;
     */
    READ_CLIENT_DATA = 2,
    /**
     * @generated from protobuf enum value: READ_SERVER_CONFIG = 3;
     */
    READ_SERVER_CONFIG = 3,
    /**
     * @generated from protobuf enum value: UPDATE_SERVER_CONFIG = 4;
     */
    UPDATE_SERVER_CONFIG = 4,
    /**
     * @generated from protobuf enum value: CREATE_USER = 5;
     */
    CREATE_USER = 5,
    /**
     * @generated from protobuf enum value: READ_USER = 6;
     */
    READ_USER = 6,
    /**
     * @generated from protobuf enum value: UPDATE_USER = 7;
     */
    UPDATE_USER = 7,
    /**
     * @generated from protobuf enum value: DELETE_USER = 8;
     */
    DELETE_USER = 8,
    /**
     * @generated from protobuf enum value: GLOBAL_COMMAND_EXECUTION = 9;
     */
    GLOBAL_COMMAND_EXECUTION = 9,
    /**
     * @generated from protobuf enum value: INVALIDATE_SESSIONS = 11;
     */
    INVALIDATE_SESSIONS = 11,
    /**
     * @generated from protobuf enum value: GENERATE_SELF_WEBDAV_TOKEN = 12;
     */
    GENERATE_SELF_WEBDAV_TOKEN = 12,
    /**
     * @generated from protobuf enum value: GENERATE_SELF_API_TOKEN = 15;
     */
    GENERATE_SELF_API_TOKEN = 15,
    /**
     * @generated from protobuf enum value: UPDATE_SELF_USERNAME = 13;
     */
    UPDATE_SELF_USERNAME = 13,
    /**
     * @generated from protobuf enum value: UPDATE_SELF_EMAIL = 14;
     */
    UPDATE_SELF_EMAIL = 14,
    /**
     * @generated from protobuf enum value: GENERATE_API_TOKEN = 16;
     */
    GENERATE_API_TOKEN = 16,
    /**
     * @generated from protobuf enum value: INVALIDATE_SELF_SESSIONS = 17;
     */
    INVALIDATE_SELF_SESSIONS = 17,
    /**
     * @generated from protobuf enum value: CREATE_GLOBAL_SCRIPT = 18;
     */
    CREATE_GLOBAL_SCRIPT = 18,
    /**
     * @generated from protobuf enum value: READ_GLOBAL_SCRIPT = 19;
     */
    READ_GLOBAL_SCRIPT = 19,
    /**
     * @generated from protobuf enum value: UPDATE_GLOBAL_SCRIPT = 20;
     */
    UPDATE_GLOBAL_SCRIPT = 20,
    /**
     * @generated from protobuf enum value: DELETE_GLOBAL_SCRIPT = 21;
     */
    DELETE_GLOBAL_SCRIPT = 21,
    /**
     * @generated from protobuf enum value: ELEVATE_SCRIPT_PERMISSIONS = 22;
     */
    ELEVATE_SCRIPT_PERMISSIONS = 22
}
/**
 * @generated from protobuf enum soulfire.v1.InstancePermission
 */
export enum InstancePermission {
    /**
     * @generated from protobuf enum value: INSTANCE_COMMAND_EXECUTION = 0;
     */
    INSTANCE_COMMAND_EXECUTION = 0,
    /**
     * @generated from protobuf enum value: READ_INSTANCE = 2;
     */
    READ_INSTANCE = 2,
    /**
     * @generated from protobuf enum value: UPDATE_INSTANCE_META = 3;
     */
    UPDATE_INSTANCE_META = 3,
    /**
     * @generated from protobuf enum value: UPDATE_INSTANCE_CONFIG = 14;
     */
    UPDATE_INSTANCE_CONFIG = 14,
    /**
     * @generated from protobuf enum value: DELETE_INSTANCE = 4;
     */
    DELETE_INSTANCE = 4,
    /**
     * @generated from protobuf enum value: CHANGE_INSTANCE_STATE = 5;
     */
    CHANGE_INSTANCE_STATE = 5,
    /**
     * @generated from protobuf enum value: AUTHENTICATE_MC_ACCOUNT = 6;
     */
    AUTHENTICATE_MC_ACCOUNT = 6,
    /**
     * @generated from protobuf enum value: CHECK_PROXY = 7;
     */
    CHECK_PROXY = 7,
    /**
     * @generated from protobuf enum value: DOWNLOAD_URL = 8;
     */
    DOWNLOAD_URL = 8,
    /**
     * @generated from protobuf enum value: ACCESS_OBJECT_STORAGE = 9;
     */
    ACCESS_OBJECT_STORAGE = 9,
    /**
     * @generated from protobuf enum value: ACCESS_SCRIPT_CODE_OBJECT_STORAGE = 20;
     */
    ACCESS_SCRIPT_CODE_OBJECT_STORAGE = 20,
    /**
     * @generated from protobuf enum value: INSTANCE_SUBSCRIBE_LOGS = 13;
     */
    INSTANCE_SUBSCRIBE_LOGS = 13,
    /**
     * @generated from protobuf enum value: READ_INSTANCE_AUDIT_LOGS = 15;
     */
    READ_INSTANCE_AUDIT_LOGS = 15,
    /**
     * @generated from protobuf enum value: CREATE_SCRIPT = 16;
     */
    CREATE_SCRIPT = 16,
    /**
     * @generated from protobuf enum value: READ_SCRIPT = 17;
     */
    READ_SCRIPT = 17,
    /**
     * @generated from protobuf enum value: UPDATE_SCRIPT = 18;
     */
    UPDATE_SCRIPT = 18,
    /**
     * @generated from protobuf enum value: DELETE_SCRIPT = 19;
     */
    DELETE_SCRIPT = 19
}
/**
 * @generated from protobuf enum soulfire.v1.UserRole
 */
export enum UserRole {
    /**
     * @generated from protobuf enum value: ADMIN = 0;
     */
    ADMIN = 0,
    /**
     * @generated from protobuf enum value: USER = 1;
     */
    USER = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class ProxyProto$Type extends MessageType<ProxyProto> {
    constructor() {
        super("soulfire.v1.ProxyProto", [
            { no: 1, name: "type", kind: "enum", T: () => ["soulfire.v1.ProxyProto.Type", ProxyProto_Type] },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "username", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "password", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ProxyProto
 */
export const ProxyProto = new ProxyProto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto$Type extends MessageType<MinecraftAccountProto> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto", [
            { no: 1, name: "type", kind: "enum", T: () => ["soulfire.v1.MinecraftAccountProto.AccountTypeProto", MinecraftAccountProto_AccountTypeProto] },
            { no: 2, name: "profile_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_known_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "online_simple_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OnlineSimpleJavaData },
            { no: 5, name: "online_chain_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OnlineChainJavaData },
            { no: 6, name: "offline_java_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_OfflineJavaData },
            { no: 7, name: "bedrock_data", kind: "message", oneof: "accountData", T: () => MinecraftAccountProto_BedrockData }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto
 */
export const MinecraftAccountProto = new MinecraftAccountProto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OnlineSimpleJavaData$Type extends MessageType<MinecraftAccountProto_OnlineSimpleJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData", [
            { no: 1, name: "auth_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_expire_at", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OnlineSimpleJavaData
 */
export const MinecraftAccountProto_OnlineSimpleJavaData = new MinecraftAccountProto_OnlineSimpleJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OnlineChainJavaData$Type extends MessageType<MinecraftAccountProto_OnlineChainJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OnlineChainJavaData", [
            { no: 1, name: "auth_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_expire_at", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "auth_chain", kind: "message", T: () => Struct }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OnlineChainJavaData
 */
export const MinecraftAccountProto_OnlineChainJavaData = new MinecraftAccountProto_OnlineChainJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_OfflineJavaData$Type extends MessageType<MinecraftAccountProto_OfflineJavaData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.OfflineJavaData", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.OfflineJavaData
 */
export const MinecraftAccountProto_OfflineJavaData = new MinecraftAccountProto_OfflineJavaData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinecraftAccountProto_BedrockData$Type extends MessageType<MinecraftAccountProto_BedrockData> {
    constructor() {
        super("soulfire.v1.MinecraftAccountProto.BedrockData", [
            { no: 1, name: "mojang_jwt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "identity_jwt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "public_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "private_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "play_fab_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "auth_chain", kind: "message", T: () => Struct }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinecraftAccountProto.BedrockData
 */
export const MinecraftAccountProto_BedrockData = new MinecraftAccountProto_BedrockData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsEntry$Type extends MessageType<SettingsEntry> {
    constructor() {
        super("soulfire.v1.SettingsEntry", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "message", T: () => Value }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsEntry
 */
export const SettingsEntry = new SettingsEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsNamespace$Type extends MessageType<SettingsNamespace> {
    constructor() {
        super("soulfire.v1.SettingsNamespace", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsEntry }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsNamespace
 */
export const SettingsNamespace = new SettingsNamespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringSetting$Type extends MessageType<StringSetting> {
    constructor() {
        super("soulfire.v1.StringSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "input_type", kind: "enum", T: () => ["soulfire.v1.StringSetting.InputType", StringSetting_InputType] },
            { no: 6, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "min_length", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "max_length", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "pattern", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.StringSetting
 */
export const StringSetting = new StringSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntSetting$Type extends MessageType<IntSetting> {
    constructor() {
        super("soulfire.v1.IntSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "min", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "step", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "thousand_separator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.IntSetting
 */
export const IntSetting = new IntSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoubleSetting$Type extends MessageType<DoubleSetting> {
    constructor() {
        super("soulfire.v1.DoubleSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "min", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "max", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "step", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "thousand_separator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "decimal_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "fixed_decimal_scale", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.DoubleSetting
 */
export const DoubleSetting = new DoubleSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoolSetting$Type extends MessageType<BoolSetting> {
    constructor() {
        super("soulfire.v1.BoolSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.BoolSetting
 */
export const BoolSetting = new BoolSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComboSetting$Type extends MessageType<ComboSetting> {
    constructor() {
        super("soulfire.v1.ComboSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ComboSetting_Option },
            { no: 4, name: "def", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ComboSetting
 */
export const ComboSetting = new ComboSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComboSetting_Option$Type extends MessageType<ComboSetting_Option> {
    constructor() {
        super("soulfire.v1.ComboSetting.Option", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "icon_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "keywords", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ComboSetting.Option
 */
export const ComboSetting_Option = new ComboSetting_Option$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StringListSetting$Type extends MessageType<StringListSetting> {
    constructor() {
        super("soulfire.v1.StringListSetting", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.StringListSetting
 */
export const StringListSetting = new StringListSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinMaxSetting$Type extends MessageType<MinMaxSetting> {
    constructor() {
        super("soulfire.v1.MinMaxSetting", [
            { no: 1, name: "min", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "step", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "thousand_separator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "minEntry", kind: "message", T: () => MinMaxSetting_Entry },
            { no: 6, name: "maxEntry", kind: "message", T: () => MinMaxSetting_Entry },
            { no: 7, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinMaxSetting
 */
export const MinMaxSetting = new MinMaxSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MinMaxSetting_Entry$Type extends MessageType<MinMaxSetting_Entry> {
    constructor() {
        super("soulfire.v1.MinMaxSetting.Entry", [
            { no: 1, name: "ui_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "def", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "placeholder", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.MinMaxSetting.Entry
 */
export const MinMaxSetting_Entry = new MinMaxSetting_Entry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingEntry$Type extends MessageType<SettingEntry> {
    constructor() {
        super("soulfire.v1.SettingEntry", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "string", kind: "message", oneof: "value", T: () => StringSetting },
            { no: 3, name: "int", kind: "message", oneof: "value", T: () => IntSetting },
            { no: 4, name: "double", kind: "message", oneof: "value", T: () => DoubleSetting },
            { no: 5, name: "bool", kind: "message", oneof: "value", T: () => BoolSetting },
            { no: 6, name: "combo", kind: "message", oneof: "value", T: () => ComboSetting },
            { no: 7, name: "string_list", kind: "message", oneof: "value", T: () => StringListSetting },
            { no: 8, name: "min_max", kind: "message", oneof: "value", T: () => MinMaxSetting }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingEntry
 */
export const SettingEntry = new SettingEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettingsPage$Type extends MessageType<SettingsPage> {
    constructor() {
        super("soulfire.v1.SettingsPage", [
            { no: 2, name: "owning_plugin", kind: "message", T: () => ServerPlugin },
            { no: 3, name: "page_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingEntry },
            { no: 6, name: "icon_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "enabled_key", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.SettingsPage
 */
export const SettingsPage = new SettingsPage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerPlugin$Type extends MessageType<ServerPlugin> {
    constructor() {
        super("soulfire.v1.ServerPlugin", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "author", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "license", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "website", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerPlugin
 */
export const ServerPlugin = new ServerPlugin$Type();
