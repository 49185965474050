/* eslint-disable */
// @generated by protobuf-ts 2.9.6 with parameter long_type_string,optimize_code_size,eslint_disable,ts_nocheck
// @generated from protobuf file "soulfire/client.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { UserRole } from "./common";
import { GlobalPermission } from "./common";
/**
 * @generated from protobuf message soulfire.v1.ClientDataRequest
 */
export interface ClientDataRequest {
}
/**
 * @generated from protobuf message soulfire.v1.GlobalPermissionState
 */
export interface GlobalPermissionState {
    /**
     * @generated from protobuf field: soulfire.v1.GlobalPermission global_permission = 1;
     */
    globalPermission: GlobalPermission;
    /**
     * @generated from protobuf field: bool granted = 2;
     */
    granted: boolean;
}
/**
 * @generated from protobuf message soulfire.v1.ServerInfo
 */
export interface ServerInfo {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string commit_hash = 2;
     */
    commitHash: string;
    /**
     * @generated from protobuf field: string branch_name = 3;
     */
    branchName: string;
    /**
     * @generated from protobuf field: string public_api_address = 4;
     */
    publicApiAddress: string;
    /**
     * @generated from protobuf field: string public_webdav_address = 5;
     */
    publicWebdavAddress: string;
    /**
     * @generated from protobuf field: string public_docs_address = 6;
     */
    publicDocsAddress: string;
}
/**
 * @generated from protobuf message soulfire.v1.ClientDataResponse
 */
export interface ClientDataResponse {
    /**
     * @generated from protobuf field: string id = 6;
     */
    id: string;
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: soulfire.v1.UserRole role = 7;
     */
    role: UserRole;
    /**
     * @generated from protobuf field: string email = 8;
     */
    email: string;
    /**
     * @generated from protobuf field: repeated soulfire.v1.GlobalPermissionState server_permissions = 2;
     */
    serverPermissions: GlobalPermissionState[];
    /**
     * @generated from protobuf field: soulfire.v1.ServerInfo server_info = 11;
     */
    serverInfo?: ServerInfo;
}
/**
 * @generated from protobuf message soulfire.v1.GenerateWebDAVTokenRequest
 */
export interface GenerateWebDAVTokenRequest {
}
/**
 * @generated from protobuf message soulfire.v1.GenerateWebDAVTokenResponse
 */
export interface GenerateWebDAVTokenResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message soulfire.v1.GenerateAPITokenRequest
 */
export interface GenerateAPITokenRequest {
}
/**
 * @generated from protobuf message soulfire.v1.GenerateAPITokenResponse
 */
export interface GenerateAPITokenResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message soulfire.v1.UpdateSelfUsernameRequest
 */
export interface UpdateSelfUsernameRequest {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
}
/**
 * @generated from protobuf message soulfire.v1.UpdateSelfUsernameResponse
 */
export interface UpdateSelfUsernameResponse {
}
/**
 * @generated from protobuf message soulfire.v1.UpdateSelfEmailRequest
 */
export interface UpdateSelfEmailRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message soulfire.v1.UpdateSelfEmailResponse
 */
export interface UpdateSelfEmailResponse {
}
/**
 * @generated from protobuf message soulfire.v1.InvalidateSelfSessionsRequest
 */
export interface InvalidateSelfSessionsRequest {
}
/**
 * @generated from protobuf message soulfire.v1.InvalidateSelfSessionsResponse
 */
export interface InvalidateSelfSessionsResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class ClientDataRequest$Type extends MessageType<ClientDataRequest> {
    constructor() {
        super("soulfire.v1.ClientDataRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ClientDataRequest
 */
export const ClientDataRequest = new ClientDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalPermissionState$Type extends MessageType<GlobalPermissionState> {
    constructor() {
        super("soulfire.v1.GlobalPermissionState", [
            { no: 1, name: "global_permission", kind: "enum", T: () => ["soulfire.v1.GlobalPermission", GlobalPermission] },
            { no: 2, name: "granted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalPermissionState
 */
export const GlobalPermissionState = new GlobalPermissionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerInfo$Type extends MessageType<ServerInfo> {
    constructor() {
        super("soulfire.v1.ServerInfo", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "commit_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "branch_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "public_api_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "public_webdav_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "public_docs_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerInfo
 */
export const ServerInfo = new ServerInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientDataResponse$Type extends MessageType<ClientDataResponse> {
    constructor() {
        super("soulfire.v1.ClientDataResponse", [
            { no: 6, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "role", kind: "enum", T: () => ["soulfire.v1.UserRole", UserRole] },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "server_permissions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GlobalPermissionState },
            { no: 11, name: "server_info", kind: "message", T: () => ServerInfo }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ClientDataResponse
 */
export const ClientDataResponse = new ClientDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateWebDAVTokenRequest$Type extends MessageType<GenerateWebDAVTokenRequest> {
    constructor() {
        super("soulfire.v1.GenerateWebDAVTokenRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GenerateWebDAVTokenRequest
 */
export const GenerateWebDAVTokenRequest = new GenerateWebDAVTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateWebDAVTokenResponse$Type extends MessageType<GenerateWebDAVTokenResponse> {
    constructor() {
        super("soulfire.v1.GenerateWebDAVTokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GenerateWebDAVTokenResponse
 */
export const GenerateWebDAVTokenResponse = new GenerateWebDAVTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateAPITokenRequest$Type extends MessageType<GenerateAPITokenRequest> {
    constructor() {
        super("soulfire.v1.GenerateAPITokenRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GenerateAPITokenRequest
 */
export const GenerateAPITokenRequest = new GenerateAPITokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateAPITokenResponse$Type extends MessageType<GenerateAPITokenResponse> {
    constructor() {
        super("soulfire.v1.GenerateAPITokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GenerateAPITokenResponse
 */
export const GenerateAPITokenResponse = new GenerateAPITokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfUsernameRequest$Type extends MessageType<UpdateSelfUsernameRequest> {
    constructor() {
        super("soulfire.v1.UpdateSelfUsernameRequest", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateSelfUsernameRequest
 */
export const UpdateSelfUsernameRequest = new UpdateSelfUsernameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfUsernameResponse$Type extends MessageType<UpdateSelfUsernameResponse> {
    constructor() {
        super("soulfire.v1.UpdateSelfUsernameResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateSelfUsernameResponse
 */
export const UpdateSelfUsernameResponse = new UpdateSelfUsernameResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfEmailRequest$Type extends MessageType<UpdateSelfEmailRequest> {
    constructor() {
        super("soulfire.v1.UpdateSelfEmailRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateSelfEmailRequest
 */
export const UpdateSelfEmailRequest = new UpdateSelfEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfEmailResponse$Type extends MessageType<UpdateSelfEmailResponse> {
    constructor() {
        super("soulfire.v1.UpdateSelfEmailResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.UpdateSelfEmailResponse
 */
export const UpdateSelfEmailResponse = new UpdateSelfEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvalidateSelfSessionsRequest$Type extends MessageType<InvalidateSelfSessionsRequest> {
    constructor() {
        super("soulfire.v1.InvalidateSelfSessionsRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InvalidateSelfSessionsRequest
 */
export const InvalidateSelfSessionsRequest = new InvalidateSelfSessionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvalidateSelfSessionsResponse$Type extends MessageType<InvalidateSelfSessionsResponse> {
    constructor() {
        super("soulfire.v1.InvalidateSelfSessionsResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InvalidateSelfSessionsResponse
 */
export const InvalidateSelfSessionsResponse = new InvalidateSelfSessionsResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.ClientService
 */
export const ClientService = new ServiceType("soulfire.v1.ClientService", [
    { name: "GetClientData", options: {}, I: ClientDataRequest, O: ClientDataResponse },
    { name: "GenerateWebDAVToken", options: {}, I: GenerateWebDAVTokenRequest, O: GenerateWebDAVTokenResponse },
    { name: "GenerateAPIToken", options: {}, I: GenerateAPITokenRequest, O: GenerateAPITokenResponse },
    { name: "UpdateSelfUsername", options: {}, I: UpdateSelfUsernameRequest, O: UpdateSelfUsernameResponse },
    { name: "UpdateSelfEmail", options: {}, I: UpdateSelfEmailRequest, O: UpdateSelfEmailResponse },
    { name: "InvalidateSelfSessions", options: {}, I: InvalidateSelfSessionsRequest, O: InvalidateSelfSessionsResponse }
]);
