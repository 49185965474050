/* eslint-disable */
// @generated by protobuf-ts 2.9.6 with parameter long_type_string,optimize_code_size,eslint_disable,ts_nocheck
// @generated from protobuf file "soulfire/logs.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message soulfire.v1.LogString
 */
export interface LogString {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: optional string instance_id = 3;
     */
    instanceId?: string;
    /**
     * @generated from protobuf field: optional string bot_id = 4;
     */
    botId?: string;
    /**
     * @generated from protobuf field: optional string script_id = 5;
     */
    scriptId?: string;
}
/**
 * @generated from protobuf message soulfire.v1.GlobalLogScope
 */
export interface GlobalLogScope {
}
/**
 * @generated from protobuf message soulfire.v1.InstanceLogScope
 */
export interface InstanceLogScope {
    /**
     * @generated from protobuf field: string instance_id = 1;
     */
    instanceId: string;
}
/**
 * @generated from protobuf message soulfire.v1.BotLogScope
 */
export interface BotLogScope {
    /**
     * @generated from protobuf field: string instance_id = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: string bot_id = 2;
     */
    botId: string;
}
/**
 * @generated from protobuf message soulfire.v1.GlobalScriptLogScope
 */
export interface GlobalScriptLogScope {
    /**
     * @generated from protobuf field: string script_id = 1;
     */
    scriptId: string;
}
/**
 * @generated from protobuf message soulfire.v1.InstanceScriptLogScope
 */
export interface InstanceScriptLogScope {
    /**
     * @generated from protobuf field: string instance_id = 1;
     */
    instanceId: string;
    /**
     * @generated from protobuf field: string script_id = 2;
     */
    scriptId: string;
}
/**
 * @generated from protobuf message soulfire.v1.LogScope
 */
export interface LogScope {
    /**
     * @generated from protobuf oneof: scope
     */
    scope: {
        oneofKind: "global";
        /**
         * @generated from protobuf field: soulfire.v1.GlobalLogScope global = 1;
         */
        global: GlobalLogScope;
    } | {
        oneofKind: "instance";
        /**
         * @generated from protobuf field: soulfire.v1.InstanceLogScope instance = 2;
         */
        instance: InstanceLogScope;
    } | {
        oneofKind: "bot";
        /**
         * @generated from protobuf field: soulfire.v1.BotLogScope bot = 3;
         */
        bot: BotLogScope;
    } | {
        oneofKind: "globalScript";
        /**
         * @generated from protobuf field: soulfire.v1.GlobalScriptLogScope global_script = 4;
         */
        globalScript: GlobalScriptLogScope;
    } | {
        oneofKind: "instanceScript";
        /**
         * @generated from protobuf field: soulfire.v1.InstanceScriptLogScope instance_script = 5;
         */
        instanceScript: InstanceScriptLogScope;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.PreviousLogRequest
 */
export interface PreviousLogRequest {
    /**
     * @generated from protobuf field: soulfire.v1.LogScope scope = 4;
     */
    scope?: LogScope;
    /**
     * @generated from protobuf field: int32 count = 3;
     */
    count: number;
}
/**
 * @generated from protobuf message soulfire.v1.PreviousLogResponse
 */
export interface PreviousLogResponse {
    /**
     * @generated from protobuf field: repeated soulfire.v1.LogString messages = 1;
     */
    messages: LogString[];
}
/**
 * @generated from protobuf message soulfire.v1.LogRequest
 */
export interface LogRequest {
    /**
     * @generated from protobuf field: soulfire.v1.LogScope scope = 3;
     */
    scope?: LogScope;
}
/**
 * @generated from protobuf message soulfire.v1.LogResponse
 */
export interface LogResponse {
    /**
     * @generated from protobuf field: soulfire.v1.LogString message = 1;
     */
    message?: LogString;
}
// @generated message type with reflection information, may provide speed optimized methods
class LogString$Type extends MessageType<LogString> {
    constructor() {
        super("soulfire.v1.LogString", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "instance_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "bot_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "script_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogString
 */
export const LogString = new LogString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalLogScope$Type extends MessageType<GlobalLogScope> {
    constructor() {
        super("soulfire.v1.GlobalLogScope", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalLogScope
 */
export const GlobalLogScope = new GlobalLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceLogScope$Type extends MessageType<InstanceLogScope> {
    constructor() {
        super("soulfire.v1.InstanceLogScope", [
            { no: 1, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceLogScope
 */
export const InstanceLogScope = new InstanceLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BotLogScope$Type extends MessageType<BotLogScope> {
    constructor() {
        super("soulfire.v1.BotLogScope", [
            { no: 1, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bot_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.BotLogScope
 */
export const BotLogScope = new BotLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalScriptLogScope$Type extends MessageType<GlobalScriptLogScope> {
    constructor() {
        super("soulfire.v1.GlobalScriptLogScope", [
            { no: 1, name: "script_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.GlobalScriptLogScope
 */
export const GlobalScriptLogScope = new GlobalScriptLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InstanceScriptLogScope$Type extends MessageType<InstanceScriptLogScope> {
    constructor() {
        super("soulfire.v1.InstanceScriptLogScope", [
            { no: 1, name: "instance_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "script_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.InstanceScriptLogScope
 */
export const InstanceScriptLogScope = new InstanceScriptLogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogScope$Type extends MessageType<LogScope> {
    constructor() {
        super("soulfire.v1.LogScope", [
            { no: 1, name: "global", kind: "message", oneof: "scope", T: () => GlobalLogScope },
            { no: 2, name: "instance", kind: "message", oneof: "scope", T: () => InstanceLogScope },
            { no: 3, name: "bot", kind: "message", oneof: "scope", T: () => BotLogScope },
            { no: 4, name: "global_script", kind: "message", oneof: "scope", T: () => GlobalScriptLogScope },
            { no: 5, name: "instance_script", kind: "message", oneof: "scope", T: () => InstanceScriptLogScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogScope
 */
export const LogScope = new LogScope$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogRequest$Type extends MessageType<PreviousLogRequest> {
    constructor() {
        super("soulfire.v1.PreviousLogRequest", [
            { no: 4, name: "scope", kind: "message", T: () => LogScope },
            { no: 3, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogRequest
 */
export const PreviousLogRequest = new PreviousLogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousLogResponse$Type extends MessageType<PreviousLogResponse> {
    constructor() {
        super("soulfire.v1.PreviousLogResponse", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LogString }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.PreviousLogResponse
 */
export const PreviousLogResponse = new PreviousLogResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogRequest$Type extends MessageType<LogRequest> {
    constructor() {
        super("soulfire.v1.LogRequest", [
            { no: 3, name: "scope", kind: "message", T: () => LogScope }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogRequest
 */
export const LogRequest = new LogRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogResponse$Type extends MessageType<LogResponse> {
    constructor() {
        super("soulfire.v1.LogResponse", [
            { no: 1, name: "message", kind: "message", T: () => LogString }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LogResponse
 */
export const LogResponse = new LogResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.LogsService
 */
export const LogsService = new ServiceType("soulfire.v1.LogsService", [
    { name: "GetPrevious", options: {}, I: PreviousLogRequest, O: PreviousLogResponse },
    { name: "Subscribe", serverStreaming: true, options: {}, I: LogRequest, O: LogResponse }
]);
