/* eslint-disable */
// @generated by protobuf-ts 2.9.6 with parameter long_type_string,optimize_code_size,eslint_disable,ts_nocheck
// @generated from protobuf file "soulfire/server.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
import { SettingsPage } from "./common";
import { SettingsNamespace } from "./common";
/**
 * @generated from protobuf message soulfire.v1.ServerConfig
 */
export interface ServerConfig {
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsNamespace settings = 1;
     */
    settings: SettingsNamespace[];
}
/**
 * @generated from protobuf message soulfire.v1.ServerInfoRequest
 */
export interface ServerInfoRequest {
}
/**
 * @generated from protobuf message soulfire.v1.ServerInfoResponse
 */
export interface ServerInfoResponse {
    /**
     * @generated from protobuf field: soulfire.v1.ServerConfig config = 1;
     */
    config?: ServerConfig;
    /**
     * @generated from protobuf field: repeated soulfire.v1.SettingsPage server_settings = 2;
     */
    serverSettings: SettingsPage[];
}
/**
 * @generated from protobuf message soulfire.v1.ServerUpdateConfigRequest
 */
export interface ServerUpdateConfigRequest {
    /**
     * @generated from protobuf field: soulfire.v1.ServerConfig config = 1;
     */
    config?: ServerConfig;
}
/**
 * @generated from protobuf message soulfire.v1.ServerUpdateConfigResponse
 */
export interface ServerUpdateConfigResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class ServerConfig$Type extends MessageType<ServerConfig> {
    constructor() {
        super("soulfire.v1.ServerConfig", [
            { no: 1, name: "settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsNamespace }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerConfig
 */
export const ServerConfig = new ServerConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerInfoRequest$Type extends MessageType<ServerInfoRequest> {
    constructor() {
        super("soulfire.v1.ServerInfoRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerInfoRequest
 */
export const ServerInfoRequest = new ServerInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerInfoResponse$Type extends MessageType<ServerInfoResponse> {
    constructor() {
        super("soulfire.v1.ServerInfoResponse", [
            { no: 1, name: "config", kind: "message", T: () => ServerConfig },
            { no: 2, name: "server_settings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettingsPage }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerInfoResponse
 */
export const ServerInfoResponse = new ServerInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerUpdateConfigRequest$Type extends MessageType<ServerUpdateConfigRequest> {
    constructor() {
        super("soulfire.v1.ServerUpdateConfigRequest", [
            { no: 1, name: "config", kind: "message", T: () => ServerConfig }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerUpdateConfigRequest
 */
export const ServerUpdateConfigRequest = new ServerUpdateConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerUpdateConfigResponse$Type extends MessageType<ServerUpdateConfigResponse> {
    constructor() {
        super("soulfire.v1.ServerUpdateConfigResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.ServerUpdateConfigResponse
 */
export const ServerUpdateConfigResponse = new ServerUpdateConfigResponse$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.ServerService
 */
export const ServerService = new ServiceType("soulfire.v1.ServerService", [
    { name: "GetServerInfo", options: {}, I: ServerInfoRequest, O: ServerInfoResponse },
    { name: "UpdateServerConfig", options: {}, I: ServerUpdateConfigRequest, O: ServerUpdateConfigResponse }
]);
